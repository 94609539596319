.list-dashboard {
  display: grid;
  grid-template-columns: calc(100% - 109px);;
  justify-content: end;
  background-color: white;
  border-radius: 5px;
  overflow-y: hidden;

  &.full {
    grid-template-columns: 1fr;
  }

  .content-wrap {
    display: grid;
    grid-template-columns: 1fr;
    align-self: flex-start;
    height: 100%;
    align-content: start;

    .zone-nav {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      border-radius: 5px 5px 0px 0px;
      scrollbar-width: thin;
      margin: 0;
      min-height: 70px;

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #fff;
      }

      &::-webkit-scrollbar-thumb {
        background: #ddd;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #ddd;
      }

      .market-list, .sport-list {
        display: flex;
        border-bottom: 1px solid #eee;
        margin: 0;
        padding: 0;
        width: 100%;
        border-radius: 5px 5px 0px 0px;

        .nav-item {
          margin: 0;
          padding: 22px 0px;
          border-left: solid 1px #eee;
          color: #888;
          background-color: white;
          height: 100%;

          &.sport {
            padding: 8px 0px;

            .nav-link img {
              min-width: 30px;
              min-height: 30px;
              padding: 2px;
            }
          }

          &.active{
            background-color: #ffc000;
            color: white;

          }

          .nav-link {
            width: 100px;
            background-color: transparent;
            color: inherit;
            padding: 0px;
            font-size: 14px;
            border: none;
            display: grid;
            justify-content: center;
            text-align: center;
            justify-items: center;
            align-content: center;
            gap: 5px;

            img {
              width: 20px;
              height: 20px;
            }

            span {
              font-size: 12px;
              line-height: 1.2;
            }

            &.active {
              background-color: #ffc000 !important;
              color: #fff;
            }
          }

          &:last-child {
            .nav-link {
              border: none;
            }
          }
        }
      }
    }

    .tab-content {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 70px calc(100vh - 162px);

      &.list{
        overflow-y: auto;
        display: block;
        height: 100%;
        scrollbar-width: thin;
      }
    
      // overflow-y: overlay;

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-track {
        background: #fff;
      }

      &::-webkit-scrollbar-thumb {
        background: #ddd;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #ddd;
      }

        .match-list {
          display: grid;
          grid-template-columns: 1fr;
          list-style: none;
          row-gap: 10px;
          grid-row-gap: 10px;
          margin-bottom: 12px;
          width: 100%;  
          padding: 12px;

          li {
            width: 100%;
          }
        }
    }
  }
}

.match-card {
  width: 100%;
  background: #2855a7;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  color: #fff;

  .sport {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .middle-info{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    padding: 8px 0px;

    .number {
      background-color: white;
      color: #2855a7;
      padding: 4px;
      font-size: 10px;
      border-radius: 3px;
    }

    .left {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      gap: 5px;

      .team-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        font-size: 14px;
        line-height: 1.2;
      }

      .hour {
        font-size: 12px;
        color: #FFF507;
      }
    }

    .right {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: space-between;
      padding-right: 7px;
      gap: 9px;
      line-height: 1.2;

      .out-comes{
        display: flex;
        flex-direction: row;
        gap: 7px;

        .btn {
          display: grid;
          justify-content: center;
          align-items: center;
          padding: 8px;
          min-width: 52px;
          aspect-ratio: 1;
          border-radius: 0px;
          font-size: 14px;
          color: white;

          .odd {
            font-family: 'f-bold', Georgia, sans-serif;
            opacity: 1;
            font-size: 12px;
          }
  
          span {
            font-size: 11px;
            opacity: 0.5;
          }
        }

        .btn-outcome{
          background-color: #11387D;
          border: none;

          &.selected,
          &:hover {
            background-color: #ffc000;
          }

          &.marked-btn {
            background-color: #ffc000;
            border: none;
          }
        }
  
        
      }

      .tournament-info{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        font-size: 12px;
        gap: 3px;
      }
    }
  }

  .more-info {
    border-left: 1px solid hwb(0 100% 0% / 0.102);

    .btn-more{
      height: 100%;
      width: 100%;
      padding: 0px 9px 0px 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover, &:active {
        background-color: hwb(0 0% 100% / 0.1);
      }
    }
  }
}

@media (max-width: 767px) {
  .match-result {
    width: 100%;
    height: calc(100% - 362px) !important;
    overflow-y: auto;
    scrollbar-width: thin;
  }

  .list-dashboard {
    min-height: 100%;
    grid-template-columns: 1fr;
    height: fit-content;
    border-radius: 0px;

    .content-wrap {
      width: 100vw;
  
      .zone-nav {
        border-radius: 0px;
        .sport-list {
          border-radius: 0px;
        }
      }

      .tab-content .match-list {
        padding: 20px 15px;
        list-style: none;
        margin-bottom: 60px;
      }

      .tab-content.list {
        min-height: 100%;
        height: 100%;
      }
    }
  }

  .match-card {
    padding: 12px;
    display: grid;
    grid-template-columns: 1fr;

    .sport {
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      padding: 0px;
      padding-right: 12px;

      img {
        width: 25px;
        height: 25px;
      }
    }

    .middle-info{
      display: grid;
      grid-template-columns: auto;
      justify-content: stretch;
      padding: 0px;
      row-gap: 8px;
      grid-row-gap: 8px;

      .left{
        display: grid;
        grid-template-columns: auto repeat(2, 1fr) auto;
        gap: 0px;
        align-items: center;

        .team-info {
          display: grid;
          grid-template-columns: 1fr;
          width: 100%;
          align-content: end;
        }

        .end-info {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: 100%;
          padding-left: 8px;
          padding-right: 6px;

          span {
            font-size: 12px;
            text-align: right;
          }
        }
      }

      .right {
        align-items: center;
        padding-right: 0px;
        display: grid;
        grid-template-columns: 1fr;

        .out-comes {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 8px;
          grid-column-gap: 8px;
          width: 100%;
          .btn-outcome {
            min-height: 50px;
            aspect-ratio: inherit;
            border-radius: 3px;
          }
        }
      }

      .more-info {
        border: none;
        height: 100%;

        .btn-more {
          display: grid;
          padding-left: 8px;
          padding-right: 8px;
          img {
            height: 24px;
            aspect-ratio: 1;
          }
        }
      }
    }
  }

  
}
