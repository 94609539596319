.list-dashboard {
  width: 100%;
  height: 100%;
  background-color: white;

  .content {
    width: 100%;
    height: 100%;
    padding: 18px;

    .dashboard-grid {
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 18px;

      .detail-link {
        text-decoration: none;

        &.disable {
          pointer-events: none;
          cursor: not-allowed;
        }
      }
    }
  }
}

.home-card {
  background-color: #2855a7;
  border: none;
  border-radius: 5px;
  padding: 16px 10px;
  display: grid;
  text-align: center;
  color: #fff;
  justify-items: center;
  gap: 16px;
  height: 100%;
  text-transform: uppercase;
  font-size: 14px;
  min-height: 100px;
  align-items: center;

  .card-img {
    width: 30px;
    height: 30px;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: #2855a7;
    border-radius: 50px;

    .image {
      aspect-ratio: 1;
      height: inherit;
    }
  }

  .title {
    font-family: 'f-bold', Georgia, sans-serif;
    font-size: 16px;
    margin-bottom: 0px;
    line-height: 1.2;
    text-align: center;
  }

  &.disable {
    background-color: #cccccc;

    .card-img {
      background-color: #cccccc;
    }
  }
}

@media (max-width: 767px) {
  .list-dashboard .content {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    grid-row-gap: 20px;
    justify-content: stretch;

    .dashboard-grid {
      grid-template-columns: 1fr 1fr;
      row-gap: 20px;
      column-gap: 20px;
      grid-row-gap: 20px;
      grid-column-gap: 20px;
    }

    .collapse.navbar-collapse {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 20px;
      grid-row-gap: 20px;

      .navbar-nav,
      #select-date {
        display: none;
      }

      .top-bar-form {
        display: grid;
        grid-template-columns: 4fr 1fr;
        width: 100%;
        gap: 16px;

        .btn {
          min-width: 40px;
          padding: 4px;

          img {
            height: 25px;
            aspect-ratio: 1;
          }
        }
      }
    }
  }

  #select-comb,
  .form-select.control {
    width: 100%;
    border: 1px solid var(--input-border-color);
    max-height: 48px;
    min-height: 40px;
    background-size: 24px;
    padding-right: 38px;
    font-family: 'f-normal', Georgia, sans-serif;
    border-radius: 5px;
    background-position: calc(100% - 14px) 50%;
  }

  .home-card {
    padding: 16px 8px 12px 8px;
    gap: 14px;
    min-height: 85px;

    .card-img {
      height: 24px;
      width: 24px;
    }
  }

  .account-setting-container {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 3;
    left: -100%;
    transition: left 300ms ease-in-out;

    &.open {
      left: 0px;
    }

    .menu-profile {
      height: 100%;
      width: 100%;
      background-color: white;
      display: flex;
      flex-direction: column;
    }

    .menu-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px;
      gap: 20px;

      .btn-menu {
        border: 1px solid var(--input-border-color);
        border-radius: 5px;
        line-height: 1.2;
        font-size: 16px;
        padding: 16px;
        text-transform: uppercase;
      }
    }

    .btn-back {
      width: 100%;
      min-height: 80px;
      background-color: #2855a7;
      color: white;
      border-radius: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      box-shadow: 0px 4px 4px 0px hwb(0 0% 100% / 0.051);

      span {
        text-transform: uppercase;
        font-family: 'f-bold', Georgia, sans-serif;
      }

      &.btn:active,
      &.btn:hover {
        background-color: #2855a7;
        color: white;
        border-color: #2855a7;
      }

      .icon {
        height: 24px;
        aspect-ratio: 1;
        background-color: white;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 20px;
      }
    }
  }

  .reset-pin-container {
    height: 100%;
    width: 100%;
    z-index: 2;
    left: -100%;
    position: absolute;
    background-color: white;
    top: 0;
    transition: left 300ms ease-in-out;

    &.expired {
      display: grid;
      grid-template-columns: 1fr;
      animation: slide-right 300ms ease-in;


      .btn-back span:nth-child(2) {
        text-transform: uppercase;
        font-size: 24px;
        color: #2855a7;
        font-family: 'f-bold', Georgia, sans-serif;
      }
    }

    &.open {
      left: 0px;
    }

    .login .content {
      max-height: 100%;

      .panel {
        padding: 0;
        display: flex;
        flex-direction: column;

        form {
          padding: 20px;
          height: 100%;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .list-dashboard .content .dashboard-grid {
    row-gap: 10px;
    column-gap: 10px;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
  }
}

@keyframes slide-right {
  from {left: -100%;}
  to {left: 0%;}
}
