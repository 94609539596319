.not-found-container {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  .title {
    font-size: 6rem;
  }

  .sub-title {
    font-size: 2rem;
    font-weight: bold;
  }

  .btn.redirect {
    min-width: 250px;
    width: 40%;
  }

}
