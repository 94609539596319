.content {
  width: 100%;
  height: 100%;
  background-color: #fff;

  &.no-bg {
    background-color: transparent;
    max-width: calc(100% - 370px);
    height: calc(100% - 12px);
  }
}

@media (max-width: 767px) {
  .content.no-bg {
    max-width: 100%;
    height: fit-content;
    margin-bottom: 60px;
  }
}
