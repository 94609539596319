.login {
  width: 100%;
  height: 100%;
  min-height: 800px;
  overflow: auto;
  padding: 0;
  margin: 0;
  grid-row: 1 / span 2;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;


  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    padding: 0;

    .language {
      border: 1px solid #CCCCCC;
      border-radius: 5px;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    height: 100%;
    max-height: 500px;

    .panel {
      background-color: #2855a70f;

      form {
        padding: 0 0 30px;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
        row-gap: 15px;

        button[type ="submit"]{
          min-height: 50px;
        }

      }

      h1, h2 {
        text-transform: uppercase;
        text-align: center;
        color: #2855a7;
        font-weight: bold;
        font-size: 32px;
        margin-bottom: 0px;
        line-height: 1;
        // font-family: f-bold;
      }

      h2 {
        font-size: 20px;
      }

      .login-code {
        background-image: url("../assets/images/mathys/svg/person.svg");
      }

      .login-pin {
        background-image: url("../assets/images/mathys/svg/lock.svg");
      }

      .login-phone {
        background-image: url("../assets/images/mathys/svg/phone.svg");
      }

      .btn-close-modal {
        height: 40px;
        width: 40px;
        align-self: end;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #EAEAEA;
      
        .icon {
          display: block;
          height: 12px;
          aspect-ratio: 1;
          background-color: black;
          mask-repeat: no-repeat;
          mask-size: 100%;
        }
      }

      button {
        width: 100%;
      }
    }
  }
}

.ctn-input {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
  grid-row-gap: 10px;

  &.check-box {
    grid-template-columns: auto 1fr;
  }

  .form-label {
    width: 100%;
    margin: 0;
    line-height: 1;
  }

  .form-text {
    font-size: 12px;
    color: var(--ticket-badge-bg);
    margin: 0;
    padding: 0;
    line-height: 1.5;
    display: grid;
    align-items: center;
  }

  &.checkbox label {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 15px;
    justify-content: start;
    align-items: center;

    &.gray input[type="checkbox"] {
      background-color: var( --input-bg-gray);

      &::before {
        background-color: var( --input-bg-gray);
      }
    }
    

    input[type="checkbox"] {
      appearance: none;
      background-color: white;
      width: 24px;
      height: 24px;
      border-radius: 5px;
      border: none;
      display: grid;
      align-items: center;
      justify-items: center;
      grid-row-start: 1;

      &::before {
        content: "";
        width: 15px;
        height: 15px;
        background-color: white;
        mask-image: url('../assets/images/mathys/svg/tick.svg');
        mask-repeat: no-repeat;
        mask-size: 100%;
        mask-position: center;
        transition: 100ms background-color ease-in-out;
      }

      &:checked::before {
        background-color: #2855a7;
      }
    }
  }
}



@media (min-width: 768px) {
  .login {
    .header {
      div {
        &.logo {
          img {
            height: 70px;
            width: auto;
          }
        }
      }
    }

    .content {
      .panel {
        max-width: 650px;
        padding: 25px;
        border-radius: 5px;
        min-height: fit-content;
        width: 100%;
        max-height: 480px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;

        &.reset-pin-form{
          display: flex;
          flex-direction: column;
          gap: 15px;
          min-width: 450px;
        }

        form {
          padding-left: 15px;
          padding-right: 15px;
          .form-label {
            margin-bottom: 0;
            font-size: 16px;
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 767px)  {
  .login {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    row-gap: 20px;
    overflow-y: auto;
    scrollbar-width: thin;
    min-height: 300px;
    max-height: 600px;
    align-self: center;
    justify-self: center;

    .ctn-input {
      grid-row-gap: 4px;
      row-gap: 4px;
    }

    .header {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      grid-row-gap: 10px;
      row-gap: 10px;
      margin-top: 10px;


      div {
        &.logo {
          img {
            height: 60px;
            width: auto;
          }
        }
      }
    }

    .content {
      .panel {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
        padding: 0px 25px 20px;
        background-color: white;

        h1 {
          font-size: 24px;
          line-height: 1;
        }

        form .ctn-input.checkbox label input[type="checkbox"] {
          background-color: var( --input-bg-gray);

          &:checked::before {
            background-color: #2855a7;
          }

          &::before {
            background-color: var( --input-bg-gray);
          }
        }


        form .ctn-input label {
          color: var(--text-color-gray);
        }

        .btn-close-modal {
          height: 30px;
          width: 30px;
          justify-self: end;
          display: grid;
          align-items: center;
          margin-top: 20px;

        
          .icon {
            display: block;
            height: 15px;
            aspect-ratio: 1;
            background-color: black;
            mask-repeat: no-repeat;
            mask-size: 100%;
          }
        }
      }
    }

    .form-select{
      font-family: 'f-normal', Georgia, sans-serif;
      width: 75px;
      background-size: 24px;
      padding-right: 30px;
    }
  }
}
