.betslip {
  .betslip-form-main {
    width: 100%;
    height: 100%;
  }

  .content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;

    .header-container {
      display: grid;
      grid-template-columns: 1fr;
      background: #eee;

      .head {
        background-color: #2855a7;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        font-size: 14px;
        text-transform: uppercase;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;

        .count {
          padding: 0;
          background: #ffc000;
          width: 25px;
          aspect-ratio: 1;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .sub-header {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
        background: #eee;
        height: 40px;

        select {
          border: none;
          background-color: #eee;
          font-size: 14px;
          padding: 10px;
        }

        .btn-link {
          font-size: 12px;
          display: grid;
          grid-template-columns: auto auto;
          align-items: center;
          gap: 5px;
          color: #000;

          img {
            position: relative;
            bottom: 2px;
          }
        }
      }
    }

    .slip-body {
      display: grid;
      grid-template-columns: 1fr;
      height: 100%;
      align-content: space-between;
      column-gap: 2rem;
      grid-column-gap: 2rem;
      overflow-y: auto;
      scrollbar-width: thin;
    }

    .ctn-events {
      height: 100%;
      overflow-y: auto;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 3px;
        background-color: #fff;
      }

      &::-webkit-scrollbar-track {
        background: #ffffff71;
      }

      &::-webkit-scrollbar-thumb {
        background: #eee;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #eee;
      }

      .events-wrapper {
        height: 100%;
      }

      .match-list {
        border-bottom: solid 1px #eee;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-bottom: 0px;
        scrollbar-width: thin;

        .bet {
          border-bottom: solid 1px hwb(0 0% 100% / 0.102);
          display: flex;
          padding: 10px 15px 15px 10px;
          gap: 5px;
          line-height: 1.2;

          &:last-child {
            border-bottom: none;
          }

          img {
            height: 20px;
            aspect-ratio: 1;
          }

          .block-left {
            display: grid;
            width: 100%;
            text-align: left;
            row-gap: 2px;
            grid-row-gap: 2px;

            .match {
              position: relative;
              display: grid;
              grid-template-columns: 1fr 20px;
              line-height: 1.2;
              row-gap: 2px;
              grid-row-gap: 2px;

              &.no-close {
                grid-template-columns: 1fr;
              }

              span {
                font-size: 14px;
                color: #000;

                &:first-child {
                  opacity: 0.7;
                }
              }

              .btn-close {
                background: var(--bs-btn-close-bg) center/1em auto no-repeat;
                width: 20px !important;
                height: 20px !important;
                background-size: 10px !important;
                padding: 0;
              }

              .span-2 {
                grid-column: 1/3;
              }
            }

            .market,
            .number {
              font-size: 14px;
              display: grid;
              grid-template-columns: auto auto;
              justify-content: space-between;

              *:nth-child(2) {
                color: #818181;
              }
            }

            .number span {
              background-color: #2855a7;
              line-height: 1;
              color: white;
              padding: 4px 8px;
              border-radius: 3px;
            }
          }
        }
      }

      &.integral {
        max-height: max-content;
        overflow-y: hidden;
      }

      &.multiple-simple {
        max-height: 100%;
        overflow-y: hidden;
      }
    }

    .zone-bet {
      display: grid;
      gap: 10px;
      width: 100%;
      background: #fff;
      height: auto;
      padding: 12px 14px;
      border-top: solid 1px #eee;
      line-height: 1.5;

      .bet-details {
        display: flex;
        flex-direction: column;
        transition: all 300ms ease-in-out;
        gap: 4px;
      }

      .line {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
        font-size: 14px;
        align-items: center;

        span {
          font-family: 'f-bold', Georgia, sans-serif;

          &.lib {
            color: #787878;
            font-size: 14px;
            text-align: left;
            text-transform: uppercase;
            width: 100%;
            font-weight: 400;
            font-family: 'f-normal', Georgia, sans-serif;
          }
        }

        .input-betslip {
          .input-group {
            width: 150px;
            display: flex;

            .btn {
              border: none;
              cursor: pointer;
              padding: 8px;
              border-radius: 0px;
              background-color: #2855a7;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                aspect-ratio: 1;
                height: 19px;
              }
            }

            .form-label-stake {
              width: 80px;
              height: 35px;

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              input {
                height: 100%;
                font-size: 14px;
                text-align: center;
                border-radius: 0px;
                min-height: 30px;
                border-left: none;
                border-right: none;

                /* Chrome, Safari, Edge, Opera */
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                /* Firefox */
                &[type="number"] {
                  -moz-appearance: textfield;
                }
              }
            }
          }
        }

        .form-switch {
          display: flex;
          align-items: center;

          label {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 12px;

            input[type="checkbox"] {
              width: 3em;
              height: 1.8em;

              &:checked {
                background-color: #2855a7;
                border-color: #2855a7;
              }

              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }

      .div-msg {
        height: auto;
        width: 100%;
        margin: 0px;
        border-radius: 0;
        background-color: #fd4343;
        text-align: center;
        padding: 8px 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-column-start: 1;
        grid-column-end: 3;

        .close-button {
          justify-self: end;
          background: inherit;
          margin-bottom: 2px;
          border: none;
          color: white;
          padding: 0px 10px;
          &:hover,
          &:focus {
            background: hwb(0 0% 100% / 0.18);
          }
        }

        span {
          color: #fff;
          opacity: 0.8;
          font-size: 10px;
          text-transform: uppercase;
        }

        p {
          margin: 0;
          font-size: 14px;
          color: #fff;
        }
      }

      .amt-final {
        width: 100%;
        margin: auto;
        background-color: #eee;
        flex-direction: column;
        padding: 12px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        row-gap: 4px;
        margin-top: 5px;

        span {
          color: #000;
          opacity: 0.5;
          font-size: 12px;
          text-align: center;
          text-transform: uppercase;
        }

        p {
          color: #000;
          margin: 0;
          font-size: 22px;
          font-family: 'f-bold', Georgia, sans-serif;
        }
      }

      .betslip-footer {
        display: grid;
        align-items: end;
      }

      .actions {
        display: grid;
        grid-template-columns: auto 1fr;
        padding: 0px;

        .btn-primary {
          width: 100%;
          margin: auto;
          padding: 8px;
          grid-template-columns: auto 1fr;
          column-gap: 5px;
          grid-column-gap: 5px;
          text-transform: uppercase;

          .bold {
            font-family: 'f-bold', Georgia, sans-serif;
          }

          *:first-child:last-child {
            grid-column-start: 1;
            grid-column-end: 3;
          }
        }

        .btn.no-border {
          width: 100%;
          border: none;
          height: 30px;
          padding: 0px 8px;

          span {
            font-size: 16px;
          }

          &.btn-secondary {
            color: #2855a7;
            background-color: inherit;

            &:active {
              background-color: #2855a7;
              color: white;
            }
          }
        }
      }
    }
  }

  .ticket-list {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    overflow-y: auto;
    margin-bottom: 0px;
    scrollbar-width: thin;

    .ticket {
      display: grid;
      grid-template-columns: 1fr;
      padding-bottom: 20px;
      border-bottom: 5px solid #eee;

      &:last-child {
        border-bottom: 0px;
      }

      .title {
        font-family: 'f-bold', Georgia, sans-serif;
        text-transform: uppercase;
        opacity: 0.7;
        font-size: 14px;
        padding: 0.5em;
        text-align: left;
      }

      .ctn-events ul.match-list {
        border-bottom: 0px;
      }
    }
  }

  .retail-bonus-bet-container {
    display: grid;
    grid-template-columns: 1fr;
    padding: 10px 0px;
    row-gap: 12px;
    grid-row-gap: 12px;

    &.mobile {
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
      margin-bottom: 10px;
    }

    .retail-bet-section,
    .step,
    .agent-bonus-section,
    .retail-ref {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 12px;
      grid-row-gap: 12px;
    }

    .input-wrap input.form-control,
    .input-field input.form-control,
    .tel-input-group .country,
    .customer-bonus input.form-control {
      border: none;
      font-size: 16px;
      background-color: white;
      height: 40px;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .form-control.bonus-selection {
      border-color: #9d9d9d;
      font-size: 16px;
      line-height: 1;
      height: 50px;
      padding-right: 30px;
      position: relative;
      background-image: url("/assets/images/mathys/svg/chevron-down.svg");
      background-repeat: no-repeat;
      background-position: calc(100% - 0.75rem) 50%;
      background-size: 15px;
    }

    .input-wrap .error-block {
      display: grid;
      grid-template-columns: 1fr;
      color: var(--bs-red);

      span {
        font-size: 12px;
      }
    }

    #account-code-pane.active.show {
      display: grid;
      grid-template-columns: 1fr auto;
    }

    .ref-selection#myTab {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border: none;
      column-gap: 12px;
      grid-column-gap: 12px;

      label {
        font-size: 14px;
        color: black;
        position: relative;
      }

      .radio-option {
        column-gap: 12px;
        grid-column-gap: 12px;

        input[type="radio"] {
          border: none;

          &:checked::after {
            width: 10px;
            height: 10px;
            left: 5px;
            top: 5px;
            background-color: var(--container-bg-gray);
          }
        }
      }
    }

    .retail-bet-form {
      background-color: var(--input-bg-gray);
      padding: 12px;
    }

    .ctn-input.checkbox label {
      grid-column-gap: 14px;
      column-gap: 14px;

      input[type="checkbox"] {
        height: 20px;
        width: 20px;
        background-color: var(--input-bg-gray);

        &#client-bonus-checkbox {
          background-color: white;

          &::before {
            background-color: white;
          }

          &:checked::before {
            background-color: #2855a7;
          }
        }

        &::before {
          content: "";
          width: 12px;
          height: 12px;
          background-color: var(--input-bg-gray);
        }

        &:checked::before {
          background-color: #2855a7;
        }
      }

      span {
        color: var(--text-color-gray);
        font-size: 14px;
      }
    }
  }
}

@media (min-width: 768px) {
  .betslip {
    min-width: 300px;
    width: 30%;
  }
}

@media (max-width: 767px) {
  .btn.mobile-betslip-btn {
    height: 60px !important;
    width: 60px;
    border: solid 1px #fff !important;
    border-radius: 50px !important;
    bottom: 2rem;
    right: 2rem;
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
      width: 36px;
      height: 36px;
      position: absolute;
      top: 20%;
    }
  }

  .betslip.mobile {
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    position: fixed;
    transition: all 0.3s ease-in-out;
    background: #fff;
    transform: translateY(-150%);
    z-index: 3;

    &.show {
      transform: translateY(0);
    }

    .header-container .header {
      position: relative;

      .event-header {
        position: relative;
        display: grid;
        min-height: 70px;
        .event-nav {
          background-color: #2855a7;
          border: none;
          display: grid;
          grid-template-columns: auto auto;
          justify-content: start;
        }
      }
    }

    .retail-bonus-bet-container {
      padding-top: 0px;

      .input-wrap input.form-control,
      .input-field input.form-control,
      .tel-input-group .country,
      .customer-bonus input.form-control {
        background-color: var(--input-bg-gray);
      }

      .form-control.bonus-selection {
        border: none;
        background-color: var(--input-bg-gray);
        color: black;
      }

      .ctn-input.checkbox.customer-bonus label span {
        color: var(--text-color-gray);
      }

      .ctn-input.checkbox label input[type="checkbox"] {
          background-color: var(--input-bg-gray);

          &#client-bonus-checkbox{
            background-color: var(--input-bg-gray);

            &::before {
              background-color: inherit;
            }

            &:checked::before {
              background-color: #2855a7;
            }
          }
      }

      #account-code-pane.active.show {
        column-gap: 15px;
        grid-column-gap: 15px;

        .btn-icon.qr-code {
          border: 1px solid #2855a7;
          height: 40px;
          width: 40px;
          padding: 4px;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          align-content: center;

          .icon {
            height: 24px;
            background-color: #2855a7;
          }
        }
      }

      .ctn-input.checkbox label span {
        color: white;
      }

      .retail-bet-form.mobile {
        background-color: white;

        .retail-ref .ref-selection#myTab {
          background-color: white;

          .radio-option input[type="radio"] {
            background-color: var(--input-bg-gray);
          }
        }
      }
    }

    .content {
      display: grid;
      grid-template-rows: auto 1fr;

      .ctn-events.integral {
        overflow-y: auto;
      }

      .ctn-events {
        &.multiple-simple {
          max-height: fit-content;
          min-height: 100%;
          overflow-y: auto;
          scrollbar-width: thin;
        }

        .match-list {
          display: grid;
          grid-template-columns: auto;
          align-items: start;
          align-content: start;

          .bet {
            display: grid;
            grid-template-columns: 22px auto;
            padding: 20px;
            column-gap: 5px;
            grid-column-gap: 5px;

            .block-left .market span {
              color: black;
            }
          }
        }
      }
    }

    .content .zone-bet {
      position: static;
      display: grid;
      grid-template-columns: 1fr;
      padding: 0px;
      background-color:hwb(0 12% 88% / 0);
      gap: 0px;
      border: none;

      .actions .btn-primary {
        font-family: 'f-bold', Georgia, sans-serif;

        &:disabled {
          opacity: 1;
          background-color: hwb(0 19% 81% / 0.2);
        }
      }

      .betslip-footer,
      .bet-details {
        background-color: #2855a7;
        padding: 20px;
      }

      .betslip-footer {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 10px;
        grid-row-gap: 10px;
        z-index: 2;
      }

      .bet-details {
        position: absolute;
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        z-index: 1;
        overflow-y: hidden;
        min-height: fit-content;
        transform: translateY(100%);
        border-radius: 10px 10px 0px 0px;
        padding-bottom: 0px;
        row-gap: 5px;
        grid-row-gap: 5px;

        .amt-final,
        .line {
          display: grid;
          grid-template-columns: 1fr auto;
          background: none;
          justify-content: space-between;
          line-height: 1;
          column-gap: 10px;
          grid-column-gap: 10px;

          * {
            color: white;
            font-size: 16px;
            opacity: 1;
            text-transform: uppercase;
            width: 100%;

            &:first-child {
              text-align: left;
            }
          }
        }

        .amt-final {
          border-bottom: 1px solid hwb(0 100% 0% / 0.1);
          margin: 0px;
          padding: 0px;
          padding-bottom: 12px;

          * {
            color: #ffc000;
          }
        }

        &.up {
          position: relative;
          transform: translateY(0px);
        }
      }

      .btn {
        border: none;

        .icon {
          height: 25px;
          display: block;
          aspect-ratio: 1;
          mask-size: 100%;
          mask-position: center;
        }
      }

      .stake {
        display: grid;
        row-gap: 5px;
        grid-row-gap: 5px;
        justify-items: center;

        .title {
          align-self: center;
          display: flex;
          gap: 8px;
          color: white;
        }

        .input-group {
          input {
            border-radius: 0px;
            margin: 0px;
            text-align: center;
            background-color: white;

            &:focus {
              border: none;
            }
          }

          .btn {
            background-color: var(--stake-button-bg);
            border: none;

            &:disabled {
              opacity: 1;
              pointer-events: none;
            }

            .icon {
              background-color: black;
            }

            &:nth-child(1) {
              border-radius: 5px 0px 0px 5px;
            }

            &:nth-child(3) {
              border-radius: 0px 5px 5px 0px;
            }
          }
        }
      }

      .betslip-menu {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 20px;
        grid-column-gap: 20px;
        justify-content: center;

        .btn-bet-details {
          display: flex;
          flex-direction: row;
          flex: 0 1 80px;
          align-self: flex-end;
          height: 50px;
          color: white;
          gap: 5px;
          padding: 8px;
          justify-content: space-between;
          align-items: center;
          border-radius: 5px;

          .icon {
            height: 22px;
          }

          span {
            font-family: 'f-bold', Georgia, sans-serif;
          }

          .icon {
            background-color: white;
            transition: transform 200ms linear;

            &.rot-180 {
              transform: rotate(-180deg);
            }
          }
        }
      }
    }

    .ticket-list {
      height: fit-content;
      min-height: 100%;
      margin-bottom: 300px;

      .ticket {
        position: relative;

        .title {
          padding: 20px 20px 0px 20px;
        }

        .ctn-events {
          min-height: fit-content;

          .match-list {
            margin-bottom: 0px;
            overflow-y: auto;
            scrollbar-width: thin;
          }
        }

        .zone-bet {
          position: relative;
          background-color: white;
          padding: 20px;
          gap: 5px;
          z-index: 0;

          .stake {
            order: -1;

            .title {
              color: var(--text-color-gray);
              padding: 0px;
            }

            .input-group {
              margin-bottom: 10px;

              input {
                border-top: 1px solid var(--stake-button-bg);
                border-bottom: 1px solid var(--stake-button-bg);
              }
            }
          }
        }
      }
    }
  }
}
