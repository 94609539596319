/* stylelint-disable at-rule-no-unknown */
@use "styles/_login.scss";

@font-face {
  font-family: "f-thin";
  src: url("./assets/fonts/RobotoCondensed-Light.ttf");
}

@font-face {
  font-family: "f-normal";
  src: url("./assets/fonts/RobotoCondensed-Regular.ttf");
}

@font-face {
  font-family: "f-bold";
  src: url("./assets/fonts/RobotoCondensed-Bold.ttf");
}

* {
  font-family: "f-normal";
}

:root {
  --text-color-gray: #777777;
  --input-bg-gray: #eeeeee;
  --input-border-color: #eeeeee;
  --circle-border-color: #b3b3b3;
  --container-bg-gray: #4d4d4d;
  --header-text-color: #818181;
  --ticket-badge-bg: #f05a5a;
  --stake-button-bg: #d9d9d9;
}

html,
body {
  min-height: 100vh;
  height: fit-content;
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: #fff;
  overflow: hidden;
}

#root {
  height: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 70px calc(100vh - 70px);
  overflow-y: auto;
  position: relative;
}

button,
select {
  text-transform: uppercase;
  border-radius: 2px;
  font-size: 12px;
  border: solid 1px;

  &.btn-primary {
    background-color: #ffc000;
    border-color: #ffc000;
    color: #fff;

    &:hover,
    &:active {
      background-color: #ffc000 !important;
      border-color: #ffc000 !important;
      color: #fff;
    }
  }

  &.btn-secondary {
    background-color: #2855a7;
    border-color: #2855a7;
    color: #fff;

    &:hover,
    &:active {
      background-color: #2855a7;
      border-color: #2855a7;
      color: #fff;
    }
  }

  &.btn-light {
    background-color: #eee;
    border-color: #eee;
    color: #000;

    &:hover,
    &:active {
      background-color: #eee !important;
      border-color: #eee !important;
      color: #000;
    }
  }

  &.btn-link {
    background-color: transparent;
    border-color: transparent;
    color: #2855a7;
    text-decoration: none;
  }

  &.btn-outline-primary {
    background-color: transparent;
    border-color: #ffc000;
    color: #ffc000;
  }

  &.btn-outline-secondary {
    background-color: transparent;
    border-color: #2855a7;
    color: #2855a7;
  }

  &.form-select {
    background-image: url("/assets/images/mathys/svg/chevron-down.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 0.5rem) 50%;
    background-size: 10px;
    width: 55px;
    border: none;
    font-family: f-bold;
    padding: 0.375rem 1.5rem 0.375rem 0.75rem;

    option:focus {
      background-color: #2855a7;
      color: white;
    }
  }

  &:focus {
    box-shadow: none !important;
  }
}

input {
  &.form-control {
    max-height: 48px;
    min-height: 40px;
    border-radius: 5px;
    border: solid 1px #c4c4c4;
    font-size: 18px;

    &:focus {
      box-shadow: none;
      border: solid 1px #2855a7;
    }

    &::placeholder {
      color: #999999;
    }

    &.input_image {
      background-repeat: no-repeat;
      background-position: 2% 50%;
      background-size: 25px;
      padding: 0.5rem 0.5rem 0.5rem 2.5rem;

      &:focus {
        box-shadow: none;
        border: solid 1px #2855a7;
      }
    }
  }

  &[type="date"] {
    position: relative;

    &::-webkit-calendar-picker-indicator {
      opacity: 0;
      z-index: 2;
    }

    &::after {
      position: absolute;
      content: "";
      width: 20px;
      height: 20px;
      right: 0.75rem;
      z-index: 0;
      top: 15px;
      background-color: #777777;
      mask-image: url("./assets/images/mathys/svg/calender.svg");
      mask-repeat: no-repeat;
      mask-size: 100%;
      mask-position: center;
    }

    &:not(.has-value):before {
      position: absolute;
      color: #777777;
      background-color: inherit;
      content: attr(placeholder);
      width: 100%;
    }
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      border-radius: 3px;
      border: none;

      .modal-header {
        border: none;
        text-align: center;
        display: flex;
        position: relative;
        justify-content: center;

        h1 {
          font-family: f-bold;
          font-size: 24px !important;
          color: #2855a7;
        }

        .btn-close {
          margin: 0;
          background: var(--bs-btn-close-bg) center/1em auto no-repeat;
          height: 20px !important;
          position: absolute;
          right: 15px;
        }
      }

      .modal-body {
        text-align: center;
      }

      .modal-footer {
        border: none;
        justify-content: center;
        gap: 10px;

        button {
          margin: 0;
        }
      }
    }
  }
}

.div-msg {
  height: auto;
  width: 100%;
  margin: 0px;
  border-radius: 0;
  background-color: #fd4343;
  text-align: center;
  padding: 8px 10px;
  grid-column-start: 1;
  grid-column-end: 3;

  span {
    color: #fff;
    opacity: 0.8;
    font-size: 10px;
    text-transform: uppercase;
  }

  p {
    margin: 0;
    font-size: 14px;
    color: #fff;
  }
}

.comb {
  top: 10px !important;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  z-index: 999;
  width: 15vw;
}

.comb-msg {
  width: 100%;
}

#select-comb {
  width: 130px;
  color: #000;
}

.preview-data-solde {
  width: 100%;
  padding: 0px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5px;
  grid-row-gap: 5px;
  height: 100%;
  scrollbar-width: thin;

  .ticket-info {
    line-height: 1.5;
  }

  .ticket-section {
    width: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .ticket-number {
      padding: 10px 0px;
      font-weight: bold;
      font-size: 18px;
      text-align: left;
    }
  }
}

#aaa {
  display: none !important;
}

.mobile-footer {
  bottom: 0 !important;
}

.loading {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-self: center;
  text-align: center;
}

@media (max-width: 767px) {

  #root {
    overflow-y: auto;
    position: static;
    width: 100%;
    height: 100vh;
    grid-template-rows: 1fr;
  }

  .comb {
    top: 10px !important;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    z-index: 999;
    width: 90vw;
  }

  .comb-msg {
    width: 100%;
  }

  .loading {
    padding: 20px;
    top: -20%;
  }

  input.form-control {
    border: none;
    background-color: var(--input-bg-gray);
    border-radius: 5px;

    &:focus {
      background-color: var(--input-bg-gray);
    }
  }
}
