.header,
.navbar {
  background-color: #fff;
}

.header {
  padding: 16px;

  .navbar {
    height: 100%;
    width: 100%;
    padding: 0px;

    .navbar-brand {
      margin: 0px;
      img {
        height: 50px;
        width: auto;
      }
    }

    .collapse {
      justify-content: right;
      gap: 15px;

      .navbar-nav {
        .dropdown {
          .nav-link.dropdown-toggle {
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 10px;
            color: black;
            padding: 0px;
            cursor: pointer;

            img {
              border: solid 1px #ccc;
              padding: 5px;
              border-radius: 20px;
              aspect-ratio: 1;
              width: 33px;
            }
          }
        }
      }

      .top-bar-form {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-items: stretch;
        gap: 8px;

        .form-control {
          border-color: #9d9d9d;
          border-radius: 5px;
          font-size: 18px;
          color: black;

          &::placeholder {
            color: black;
          }
        }

        .btn-secondary {
          padding: 5px;
          border-radius: 5px;

          img {
            width: 32px;
            aspect-ratio: 1;
          }
        }
      }
    }
  }
}

.body {
  height: 100%;
  background-color: #f8f9fa;
  width: 100%;
  display: flex;
  gap: 10px;
  position: relative;
  padding: 10px 12px 0 0;
  overflow-y: auto;
  scrollbar-width: thin;
  flex: 1 1 800px;
  
  .sidebar {
    min-width: 120px;
    width: 15%;
    height: 100%;
    position: relative;
    background-color: #2855a7;

    .group-btn {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      background: inherit;
      position: relative;
      z-index: 3;

      .normal {
        width: 100%;
        display: grid;
        align-items: center;
        cursor: pointer;
        justify-items: center;
        padding: 5px;
        justify-content: center;
        height: 71px !important;
        background: inherit;
        color: inherit;
        text-align: center;
        line-height: 1.2;
      }

      img {
        width: 40px;
        height: 40px;
      }

      .btn-secondary {
        width: 100%;
        border-radius: 0px;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 5px;
        grid-row-gap: 5px;
        align-items: center;
        font-size: 14px;
        font-family: 'f-bold', Georgia, sans-serif;
        padding: 20px 30px;
        color: #fff;
        justify-items: center;
        text-transform: capitalize;
        border-color: hwb(0 100% 0% / 0.25);
        border-bottom-width: 1px;
        border-bottom-style: solid;
        background-color: #2855a7;

        &:hover {
          background-color: hwb(0 0% 100% / 0.1);
        }

        img {
          width: 44px;
          height: 44px;
        }

        &.disabled, &.disabled.selected {
          background-color: #2855a7;
          pointer-events: none;
          border-color: hwb(0 100% 0% / 0.25);
          border-bottom-width: 1px;
          border-bottom-style: solid;
          opacity: 1;
        }

        &.selected {
          background-color: #ffc000;
          border: none;
        }
      }
    }
  }

  .sub-menu {
    position: absolute;
    top: 0;
    left: calc(100% + 10px);
    height: 100%;
    min-width: 110px;
    transition: all 0.3s ease-in-out;
    margin: auto;
    color: #fff;
    z-index: 2;

    &.hide-sub-menu {
      left: 0;
    }

    &.add-sub-menu-width {
      width: 30%;
    }

    h1 {
      font-size: 20px;
      text-align: center;
      padding: 10px 0;
      margin: 0;
      border-bottom: solid 1px hwb(0 100% 0% / 0.286);
    }

    .content-sub-menu {
      display: flex;
      height: calc(100% - 12px);
      border-radius: 5px 0px 0px 5px;
      overflow: hidden;
      position: relative;

      .content-sport {
        width: 109px;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
          width: 1px;
        }

        &::-webkit-scrollbar-track {
          background: #ffffff71;
        }

        &::-webkit-scrollbar-thumb {
          background: #fff;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #fff;
        }

        .tab-list {
          padding: 0;
          width: 100%;
          height: 100%;
          margin: 0;
          background: #4d4d4d;

          li {
            width: 100%;

            &:first-child {
              border-radius: 5px 0px 0px 0px;
              overflow: hidden;
            }

            &:last-child {
              border-radius: 0px 0px 0px 5px;
              overflow: hidden;
            }

            img {
              width: 24px;
              height: 24px;
            }

            button {
              border: none;
              outline: none;
              background: inherit;
            }

            span {
              font-size: 14px;
            }

            .normal {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: pointer;
              gap: 8px;
              justify-items: center;
              padding: 14px 8px;
              background: #4d4d4d;
              border-radius: 0px;
              text-decoration: none;
              color: inherit;
              text-align: center;
              line-height: 1.2;

              &.selected {
                background: #ffc000;
              }

              &:hover:not(.selected) {
                background: #6a6969;
              }
            }
          }
        }
      }

      .tournament {
        width: 0px;
        min-width: 0px;
        transition: all 300ms ease-in;
        opacity: 0;
        border-left: 1px solid hwb(0 52% 48%);

        &.expand {
          min-width: 334px;
          opacity: 1;
        }

        .navs {
          display: flex;
          justify-content: space-between;
          padding: 0 14px;
          text-decoration: none;
          position: relative;
          color: inherit;
          width: 100%;
          align-items: center;
          height: 40px;

          img {
            width: 20px;
            height: 20px;
          }

          span {
            font-size: 14px;
          }

          &.title {
            cursor: unset;
            color: gainsboro;
          }

          &.top-event {
            text-transform: uppercase;
            font-family: 'f-bold', Georgia, sans-serif;
            font-size: 14px;
            min-width: 100%;
            background: #4d4d4d;
            padding-right: 0px;
            border-radius: 0px 5px 5px 0px;
            overflow: hidden;
            height: 62px;

            .close-tab {
              padding: 0px 8px 0px 4px;
              display: flex;
              flex-direction: column;
              align-items: center;
              height: 100%;
              justify-content: center;
              background: #424242;
              transition: all 300ms ease-in-out;

              &:hover {
                background: #333;
              }

              .btn {
                padding: 0px;
              }
            }
          }
        }

        ul {
          background: #4d4d4d;
          height: 100%;
          width: 90%;
          border-top: solid 1px hwb(0 100% 0% / 0.25);

          .cat-cont {
            background: #4d4d4d;

            &.active {
              color: #ffc000;
            }
          }

          .tnmt-btn {
            width: 100%;
            background: inherit;
            border: none;
            outline: none;
            padding: 0px 14px;
          }

          .openAcc {
            display: flex;
            flex-direction: column;
            overflow-y: hidden;
            list-style-type: none;
            background: #424242;
            color: white;

            .navs.sub {
              height: 40px;
              transition: height 300ms ease-in-out, padding 300ms ease-in-out;
              animation: expand 300ms ease-in-out;
            }

            &.closed {
              .navs.sub {
                height: 0px;
                min-height: 0px;
                padding-top: 0px;
                padding-bottom: 0px;
              }
            }
          }
        }
      }

      .add-tournament-width {
        width: 70%;
        height: 100%;
        overflow: hidden;
        overflow-y: overlay;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
          width: 1px;
        }

        &::-webkit-scrollbar-track {
          background: #ffffff71;
        }

        &::-webkit-scrollbar-thumb {
          background: #fff;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #fff;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .header {
    position: fixed;
    z-index: 2;
    padding: 0px;
    top: 0;
    width: 100%;
  }

  @mixin link {
    flex-direction: row;
    padding: 20px;
    position: relative;
    min-height: 64px;

    &::after {
      content: "";
      position: absolute;
      width: calc(100% - 40px);
      border-bottom: 1px solid hwb(0 100% 0% / 0.1);
      bottom: 0;
      justify-self: center;
    }

    .btn-nav {
      position: absolute;
      right: 20px;
    }
  }

  @mixin navbar {
    background: transparent;
    min-height: 70px;
    box-shadow: 0px 4px 4px 0px hwb(0 0% 100% / 0.051);
    padding: 0px 10px;
  }

  .header .navbar {
    @include navbar;
    display: grid;
    grid-template-columns: auto auto;

  }

  .header .event-header {
    @include navbar;
    min-height: 100%;
    width: 100%;
    z-index: 3;
    position: absolute;
    top: 0;
    display: flex;
    background-color: #2855A7;
    padding: 0px;

    .event-nav {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      text-decoration: none;
      color: white;
      cursor: pointer;
      padding: 0px 20px;

      &:hover {
        background-color: hwb(0 0% 100% / 0.1);
      }

      .arrow {
        height: 24px;
        aspect-ratio: 1;
      }

      span {
        font-family: 'f-bold', Georgia, sans-serif;
        font-size: 16px;
      }
    }
  }
  .header .navbar .navbar-brand img {
    height: 36px;
    width: auto;
  }

  .header .navbar .btn-link.btn-home img {
    width: 24px !important;
    height: 24px !important;
  }

  .header .navbar .navbar-toggler {
    border: 1px solid var(--input-border-color);
    grid-template-columns: repeat(3, auto);
    display: grid;
    grid-column-gap: 8px;
    column-gap: 8px;
    align-items: center;
    min-height: 40px;

    .profile {
      border-radius: 50%;
      border: 1px solid var(--circle-border-color);
      height: 20px;
      aspect-ratio: 1;
      padding: 1px;
    }

    .agent-name {
      display: flex;
      flex-direction: row;
      gap: 3px;
      font-size: 14px;
      color: black;
    }

    .arrow {
      height: 20px;
      aspect-ratio: 1;
    }
  }

  .header .element-mobile {
    width: 100%;
    padding: 10px;
    background: green;

    form {
      button {
        height: 50px !important;

        img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  .header .navbar .collapse .navbar-nav .nav-item {
    //text-align: center;
    .nav-link {
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 5px 12px;

      img {
        border: solid 1px #ccc;
        padding: 5px;
        border-radius: 20px;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .navbar-nav .dropdown-menu {
    position: static;
    border-radius: 2px;
  }

  

  .body {
    background-color: #fff;
    width: 100%;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0px;
    grid-row-gap: 0px;
    overflow-x: hidden;
    height: fit-content;
    overflow-y: auto;
    scrollbar-width: thin;
    margin-top: 70px;

    .sidebar {
      position: fixed;
      top: calc(100% - 60px);
      display: grid;
      min-width: 100%;
      max-width: 100%;
      max-height: 60px;
      z-index: 2;

      .bottom-selections {
        display: grid;
        grid-template-columns: repeat(2, auto);
        width: 100%;
        height: 100%;
        padding: 0px 20px;
        align-items: center;
        justify-content: space-between;
        z-index: 2;
        background-color: inherit;

        .menu-toggler,
        .betslip-toggler {
          display: grid;
          grid-template-columns: repeat(2, auto);
          column-gap: 12px;
          grid-column-gap: 12px;
          align-items: center;
          color: white;
          background: inherit;
          border: none;

          .icon {
            height: 24px;
            aspect-ratio: 1;
            background-color: white;
            mask-repeat: no-repeat;
            mask-size: 24px 22px;
            mask-position: center;
            transition: all 300ms ease-in-out;
          }

          .title {
            font-size: 16px;
            font-family: 'f-bold', Georgia, sans-serif;
          }
        }

        .betslip-toggler {
          padding: 8px 12px;
          border-radius: 10px;
        }

        .number-selection {
          width: 36px;
          aspect-ratio: 1;
          background-color: #ffc000;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #2855a7;
        }

        .btn-circle {
          height: 40px;
          aspect-ratio: 1;
          background: transparent;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          padding: 8px 12px;

          .icon {
            height: 24px;
            aspect-ratio: 1;
            background-color: white;
            mask-repeat: no-repeat;
            mask-size: 24px 22px;
            mask-position: center;
          }

          &:active {
            background-color: hwb(0 100% 0% / 0.1);
            .icon {
              background-color: #ffc000;
            }
          }
        }
      }

      .group-btn {
        grid-template-columns: auto auto;
        display: grid;
        position: absolute;
        transition: transform 300ms ease-in-out;
        background-color: inherit;
        min-height: 120px;
        z-index: 1;
        border-radius: 10px 10px 0px 0px;
        box-shadow: 0px -2px 9px 0px hwb(0 0% 100% / 0.15);
        column-gap: 20px;
        grid-column-gap: 20px;
        padding: 20px 20px 0px 20px;

        &.up {
          transform: translateY(-100%);
        }

        &:after {
          position: absolute;
          width: calc(100% - 40px);
          content: "";
          bottom: 0;
          left: 20px;
          border-bottom: 1px solid hwb(0 0% 100% / 0.1);
        }

        .btn-secondary {
          max-width: 100%;
          background-color: #11387d;
          max-height: 85px;
          padding: 14px 16px;
          row-gap: 0px;
          grid-row-gap: 0px;
          justify-content: space-between;
          border-radius: 10px 10px 5px 5px;
          border: none;

          &.disabled, &.selected.disabled {
            background-color: var(--stake-button-bg);
            color: white;
            opacity: 1;
            pointer-events: none;
          }

          &.selected {
            background-color: #11387d;
          }

          img {
            height: 34px;
            width: 34px;
          }

          .title {
            line-height: 1.2;
            text-transform: uppercase;
          }
        }
      }

      .sub-menu {
        left: 0;
        min-width: 100%;
        position: fixed;

        &.hide-sub-menu {
          left: -100%;
        }

        .content-sub-menu {
          display: grid;
          grid-template-columns: 1fr;
          min-height: 100%;
          border-radius: 0;

          .btn-nav {
            padding: 3px;
            min-height: 24px;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            justify-content: center;

            img {
              height: 18px;
              width: 18px;
            }
          }

          .title,
          .navs.top-event {
            width: 100%;
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: repeat(2, auto);
            align-items: center;
            justify-content: flex-start;
            padding: 20px;
            background: #2855a7;
            text-decoration: none;
            text-transform: uppercase;
            font-family: 'f-bold', Georgia, sans-serif;
            font-size: 16px;
            border-radius: 0px;
            color: inherit;
            text-align: start;
            cursor: pointer;
            column-gap: 16px;
            grid-column-gap: 16px;
            min-height: 60px;
            max-height: 80px;

            span {
              font-family: 'f-bold', Georgia, sans-serif;
              font-size: 16px;
            }
          }

          .content-sport {
            min-height: 100%;
            width: 100%;

            .tab-list {
              li {
                &:first-child,
                &:last-child {
                  border-radius: 0px;
                }

                .title span {
                  font-family: 'f-bold', Georgia, sans-serif;
                  font-size: 16px;
                }

                span {
                  text-transform: uppercase;
                }

                .normal {
                  @include link;
                  display: grid;
                  grid-template-columns: auto auto;
                  justify-content: start;
                }
              }
            }
          }

          .tournament {
            left: -100%;
            min-width: 100%;
            position: fixed;
            width: 100%;
            height: 100%;
            opacity: 1;

            ul {
              width: 100%;
              min-height: 100%;

              .arrow-icon {
                transition: transform 150ms ease-in-out;

                &.rotate {
                  transform: rotate(90deg);
                }
              }

              .openAcc {
                background: none;
                align-items: center;

                .navs {
                  background-color: #424242;
                  width: calc(100% - 40px);
                  padding-left: 16px;
                  padding-right: 8px;
                  text-transform: uppercase;

                  &::after {
                    border: none;
                  }
                }
              }

              .navs {
                @include link;
                background-color: #4d4d4d;
                text-transform: uppercase;
                display: grid;
                grid-template-columns: auto auto;
                justify-content: space-between;

                &.sub {
                  animation: expand-sm 300ms ease-in-out;
                }
                
              }
            }

            &.expand {
              left: 0%;
              opacity: 1;
              overflow-y: auto;
              scrollbar-width: thin;
            }
          }
        }
      }
    }
  }

  .betslip .btn-betslip {
    height: 60px !important;
    width: 60px;
    border: solid 1px #fff !important;
    border-radius: 50px !important;
    position: fixed;
    right: 2%;
    bottom: 5%;
    z-index: 100;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

@keyframes expand {
  0%,
  30% {
    min-height: 0px;
    height: 0px;
  }

  100% {
    min-height: 40px;
    height: 40px;
  }
}

@keyframes expand-sm {
  0%,
  30% {
    height: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  100% {
    height: 64px;
    padding-top: 28px;
    padding-bottom: 28px;
  }
}
