.modalContainer,
.go-back-to-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hwb(0 0% 100% / 0.5);
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 999;
  grid-template-columns: 1fr;

  .login {
    opacity: 1;

    .content {
      background: transparent;
    }
  }

  .container {
    max-height: 100%;
    height: 100%;
    margin-top: 40px;
    min-width: 200px;
    max-width: 360px;

    .row {
      height: 100%;
      display: grid;

      .modal-info {
        max-width: 500px;
      }

      #modal {
        height: 100%;
        display: grid;
        justify-self: center;

        .tck-prev {
          background-color: #fff;
          max-height: 90vh;
          width: 100%;
          border-radius: 5px;
          grid-row-gap: 12px;
          row-gap: 12px;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 1fr auto;
          justify-content: space-between;
          align-items: center;
          padding: 20px 16px;
          align-self: flex-start;

          .ticket {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 64px calc(100% - 64px);
            grid-row-gap: 8px;
            row-gap: 8px;
            text-align: center;
            width: 100%;
            overflow-y: hidden;
            overflow-x: hidden;
            height: 100%;
            align-items: center;

            p {
              padding: 0px 12px;
              word-break: break-all;

              &::first-letter {
                text-transform: uppercase;
              }
            }

            .img-set {
              width: 100%;

              img {
                max-width: 225px;
                width: 80%;
              }
            }

            .modal-content {
              display: grid;
              grid-template-columns: 1fr;
              padding: 12px 10px;
              overflow-y: auto;
              scrollbar-width: thin;
              grid-row-gap: 5px;
              row-gap: 5px;
              max-height: 100%;
              min-height: 60px;

              p {
                margin: 0px;

                &:first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
    }
  }
}

.lang-modal {
  background-color: white;
  min-width: 300px;
  min-height: 250px;
  padding: 30px;

  .lang-form {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    gap: 20px;

    .title {
      margin-bottom: 10px;
      line-height: 1.2;
      text-align: center;
      font-family: 'f-bold', Georgia, sans-serif;
      text-transform: uppercase;
      font-size: 16px;
    }

    .radio-option {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 15px;
      column-gap: 15px;
      align-items: center;
      justify-content: flex-start;

      input[type="radio"] {
        width: 24px;
        height: 24px;

        &:checked::after {
          background-color: black;
          left: 4px;
          top: 4px;
        }
      }
    }

    .actions{
      margin-top: 10px;
      padding: 0;
    }
  }
}

.actions {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: center;
  column-gap: 16px;
  justify-items: center;
  align-items: center;
  grid-column-gap: 16px;
  padding: 0px 12px;
  width: 100%;

  &.reverse {
    grid-template-columns: 1fr auto;
  }

  &.no-padding {
    padding: 0px;
  }

  .btn {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    padding: 8px;
    display: grid;
    align-items: center;
    justify-items: center;
    text-align: center;
    text-transform: uppercase;

    &:first-child:last-child {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    &.btn-icon {
      background-color: white;
      border: 1px solid #2855a7;
      padding: 12px;
      span {
        background-color:#2855a7;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: 100%;
        height: 25px;
        width: 25px;
      }

      &:hover, &:active {
        background-color: #2855a7;
        border: 1px solid #2855a7;

        span {
          background-color: white;
        }
      }
    }

    &.btn-danger {
      width: 100%;

      &:first-child:last-child {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    &.btn-success {
      width: 100%;
      background-color: #ffc000;
      border-color: #ffc000;
      grid-column-start: 2;
      grid-column-end: 3;

      &:first-child:last-child {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }

  .btnClose {
    background-color: #EAEAEA;

    &:nth-child(2) {
      grid-row-start: 1;
    }

    &.sm {
      width: 40px;
      height: 40px;
      padding: 4px;
    }

    &:hover {
      background-color: #d6d6d6;
    }
  }
}

.modalContainer {
  animation: popFromTop 0.2s ease-in-out forwards;
}

.go-back-to-top {
  animation: goBackToTop 0.2s ease-in-out forwards;
}

@keyframes popFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes goBackToTop {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}
