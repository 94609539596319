.details {
  display: flex;
  flex-direction: column;
  height: calc(100% - 12px);
  overflow-y: hidden;
  background-color: white;
  border-radius: 5px;
  position: relative;

  .header {
    height: 50px;
    width: 100%;
    background: #2855a7;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #fff;
    font-family: 'f-bold', Georgia, sans-serif;
    font-size: 24px;
    text-transform: uppercase;
    gap: 10px;
    border-radius: 5px 5px 0 0;
    padding: 14px 0px;

    img {
      //filter: invert(1) grayscale(1) sepia(0);
      height: 20px;
      aspect-ratio: 1;
    }

    span {
      line-height: 0.9;
      font-size: 16px;
      font-family: 'f-bold', Georgia, sans-serif;
    }
  }

  .content {
    width: 100%;
    padding: 20px;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;

    form {
      margin: 0px auto;
      display: grid;

      .content-grid {
        display: grid;
        width: 100% !important;
        justify-items: center;
        row-gap: 25px;

        .date-label {
          width: 100%;
        }
      }

      .verify-label {
        width: 100%;
      }

      input[type="number"],
      input[type="text"],
      input[type="password"],
      input[type="radio"],
      input[type="search"] {
        &:checked {
          background-color: #2855a7;
          border-color: #2855a7;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .btn-secondary {
        max-width: 433px;
        width: 100%;
        margin: auto;

        &[type="submit"] {
          height: 50px;
          border-radius: 3px;
        }
      }
    }

    .table-content {
      height: 100%;
      padding: 1em;
      overflow-y: hidden;

      .no-data {
        text-align: center;
        width: 100%;
        margin: auto;
      }

      .history-data {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        max-height: 100%;
        overflow-y: auto;
        scrollbar-width: thin;

        .table {
          width: 100%;
          border-collapse: collapse;

          thead {
            position: sticky;
            top: 0;
            z-index: 1;

            td,
            th {
              font-family: 'f-bold', Georgia, sans-serif;
            }
          }

          td,
          th {
            padding: 12px 19px;
          }

          tr {
            td {
              border: none;

              &:first-child,
              &:last-child {
                color: #777777;
              }

              &:nth-last-child(2) {
                font-weight: 700;
              }
            }

            &:nth-child(even) {
              td {
                background-color: #f5f5f5;
              }
            }

            th:last-child {
              width: 10%;
              text-align: center;
            }
          }

          & > :not(:first-child) {
            border: none;
          }
        }

        .table-dark {
          --bs-table-bg: #2855a7;
        }

        p {
          display: flex;
          gap: 1.5rem;
        }

        table tbody tr td {
          gap: 1.5rem;
          color: inherit;
          font-size: 14px;
        }
      }
    }

    .account-form {

      div:not(.content-grid) {
        max-width: 433px;
        width: 100%;
      }

      p, label {
        margin: 0px;
        color: #777777;
      }

      .activation-info, .resend-block {
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: center;
        row-gap: 4px;
        grid-row-gap: 4px;

        span {
          font-weight: 700;
          color: black;
        }
      }

      .btn-cancel {
        min-width: 120px;
        background-color: #ffc000;
        color: white;
      }

      .resend-block {
        background-color: #EEEEEE;
        padding: 20px;
        gap: 16px;

        .info {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .btn-resend {
          width: 80%;
          color: white;
          border: none;
          border-radius: 5px;
          background-color: #2855a7;
          text-transform: uppercase;
          
          &:disabled {
            background-color: #D9D9D9;
          }
        }
      }
    }
  }

  &.verifier-ticket,
  &.search,
  &.deposit,
  &.Withdrawal,
  &.code-coupon {
    .content {
      form {
        max-width: 433px;
        width: 100%;
        display: grid;

        a.btn-secondary {
          background-color: #2855a7;
          border-color: #2855a7;
          color: #fff;
          width: 50%;
          cursor: pointer;
          height: 55px;
          border-radius: 2px;
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover,
          &:active {
            background-color: #2855a7 !important;
            border-color: #2855a7 !important;
            color: #fff;
          }
        }
      }
    }
  }

  &.solde,
  &.deposit {
    .content {
      form {
        width: 100%;

        .line-solde {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
          margin: auto;
          gap: 0.5em;

          select {
            border: solid 1px #c4c4c4;
            font-size: 18px;
            text-transform: capitalize;
            font-family: 'f-normal', Georgia, sans-serif;
            height: 50px !important;
            width: 100% !important;
            background-position: 95% 50%;
            background-size: 15px;
          }
        }

        .line {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;
          height: 60px;
          background-color: #f4f4f4;
          padding: 10px 20px;

          .form-check {
            line-height: 2.3;
            display: flex;
            gap: 10px;
          }

          .form-check-label {
            text-transform: uppercase;
            font-family: 'f-bold', Georgia, sans-serif;
            color: #777;
          }

          .form-check-input[type="radio"] {
            border-radius: 50%;
            height: 25px;
            width: 25px;
          }
        }

        .block {
          padding: 1.5em;
          width: 75%;
          margin: auto;
          display: grid;

          .line-btn {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 5px;

            label.btn-secondary {
              height: 55px;
              border-radius: 2px;
              background-color: #2855a7;
              border-color: #2855a7;
              display: flex;
              align-items: center;
              width: 100%;
              justify-content: center;
            }

            .btn-check:checked + .btn,
            :not(.btn-check) + .btn:active,
            .btn:first-child:active,
            .btn.active,
            .btn.show {
              color: #fff;
              background-color: #ffc000;
              border-color: #ffc000;
            }
          }
        }

        a.btn-secondary {
          background-color: #2855a7;
          border-color: #2855a7;
          color: #fff;
          width: 50%;
          cursor: pointer;
          height: 55px;
          border-radius: 2px;
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover,
          &:active {
            background-color: #2855a7 !important;
            border-color: #2855a7 !important;
            color: #fff;
          }
        }
      }

      &.ctn-deposit {
        padding: 0 0 20px;

        form .block {
          padding: 0;
        }
      }
    }
  }

  &.history {
    .content {
      .nav-tabs {
        max-width: 433px;
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 auto;
        background: #efefef;
        border: none;
        padding: 0px 40px;
        margin-bottom: 20px;

        li {
          width: 50%;
          border: none;

          .nav-link {
            width: 100%;
            border: none;
            color: #ccc;

            &.active {
              color: #fff;
              background-color: #2855a7;
            }
          }
        }
      }

      form {
        width: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        gap: 20px;

        &.row-form {
          flex-direction: row;
        }

        .input-wrap {
          max-width: 433px;
          width: 100%;

          select,
          input {
            border: solid 1px #c4c4c4;
            font-size: 14px;
            font-family: 'f-normal', Georgia, sans-serif;
            height: 50px !important;
            width: 100%;

            &::first-letter {
              text-transform: uppercase;
            }
          }
        }

        .btn-secondary, .btn-switch {
          max-width: 433px;
          width: 100%;
          height: 50px !important;
          margin: auto auto 0;
        }

        .btn-switch {
          color: #2855a7;
          text-transform: uppercase;
        }

        .content-grid {
          .block {
            display: grid;
            column-gap: 10px;
            row-gap: 10px;
            grid-column-gap: 10px;
            grid-row-gap: 10px;
            width: 100%;
            max-width: 433px;
            grid-template-columns: 1fr 1fr 1fr;

            .btn-secondary {
              margin: 0px;
              border-radius: 3px;

              &:nth-child(3n+1):last-child {
                grid-column-start: 1;
                grid-column-end: 4;
                display: grid;
                align-items: center;
                max-width: 100%;
              }

              &:nth-child(3n+2):last-child {
                grid-column-start: 2;
                grid-column-end: 4;
              }

              &.active,
              &:hover {
                background-color: #ffc000;
                border-color: #ffc000;
              }

              &.scan {
                width: 15%;
              }
            }
          }
        }
      }

      .table-content {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        max-height: 100%;

        .statut-filter-control {
          display: grid;
          grid-template-columns: repeat(3, minmax(100px, 170px));
          gap: 10px;
          margin: 20px;
          justify-content: center;

          .btn-outline-secondary {
            height: 35px !important;
            min-width: 100px;
            padding: 0px;
            line-height: 1.2;
            font-size: 14px;
            align-self: stretch;

            &:hover,
            &.active {
              background-color: #2855a7;
              color: #fff;
              border-color: #2855a7;
            }
          }
        }
      }
    }
  }
}

.radio-option {
  display: grid;
  column-gap: 18px;
  grid-column-gap: 18px;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-content: center;

  input[type="radio"] {
    appearance: none;
    border-radius: 50%;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    background-color: white;
    border: 1px solid #818181;
    position: relative;

    &:checked {
      &::after {
        content: "";
        position: absolute;
        border-radius: 50%;
        left: 2.2px;
        top: 2.2px;
        width: 14px;
        height: 14px;
        background-color: #818181;
        animation: appear 200ms linear;
      }
    }
  }
}

.input-wrap {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 12px;
  grid-row-gap: 12px;
  align-items: flex-start;

  label {
    display: grid;
    grid-template-columns: 1fr;
    line-height: 1.2;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.tel-input-group {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 16px;
  grid-column-gap: 16px;
  width: 100%;
  align-items: stretch;

  .country {
    min-width: 60px;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
    display: grid;
    align-items: center;
    align-self: start;
    justify-content: center;
  }

  .input-field {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
    grid-row-gap: 5px;

    .error-block {
      color: #F05A5A;
      font-size: 12px;
    }
  }

  .form-control {
    border-radius: 5px;

    &.error {
      border: 1px solid #F05A5A;
    }
  }
}

@media (max-width: 767px) {
  .details {
    height: fit-content;
    margin-bottom: 60px;

    .header {
      display: none;
    }
  }

  .scanner-container {
    position: absolute;
    height: 100%;
    min-height: 100%;
    max-height: calc(100% - 160px);
    top: 0;
    width: 100%;
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    .title {
      color: #2855a7;
      font-family: 'f-bold', Georgia, sans-serif;
      width: 100%;
      text-align: left;
      margin: 0px;
      text-transform: uppercase;
    }

    .scanner {
      height: 400px;
      width: 100%;
      position: relative;
    }
  }

  .details.history .content {
    border-radius: 0px;
    gap: 24px;
    min-height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;

    .account-form div:not(.content-grid){
      max-width: 100%;
    }

    .recharge-form .content-grid .amounts.block {
      width: 100%;
      max-width: 100%;
    }

    form {
      margin-top: 0px;
      display: grid;
      grid-template-columns: 1fr;
      align-items: stretch;
      row-gap: 20px;
      grid-row-gap: 20px;

      .input-wrap {
        max-width: 100%;
      }

      div label input,
      .btn-secondary {
        width: auto;
        font-size: 1rem;
        padding: 0;
      }

      .btn-secondary {
        padding-left: 4px;
        padding-right: 4px;
        border-radius: 5px;

        &.verify-btn,
        &[type="submit"] {
          width: 100%;
          max-width: 100%;
        }
      }

      .input-wrap input {
        border: none;
      }
    }

    .table-content {
      gap: 30px;
      min-height: 100%;

      .statut-filter-control {
        display: flex;
        width: 100%;
        margin: 0px;
        margin-top: 6px;

        .form-select.control {
          background-color: white;
          color: black;

          &:disabled {
            background-color: var(--circle-border-color);
            color: var(--text-color-gray);
          }
        }
      }

      .history-data {
        gap: 15px;
        min-height: 100%;

        &-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 16px;
          border-bottom: 1px solid var(--input-border-color);

          &:last-child {
            border-bottom: none;
          }

          .column {
            display: flex;
            flex-direction: column;
            gap: 12px;
            line-height: 1;
            font-size: 14px;

            .time {
              color: var(--text-color-gray);
            }

            .ref {
              max-width: 150px;
              word-break: break-all;
              line-height: 1.2;
              color: black;
            }

            .amount {
              font-family: 'f-bold', Georgia, sans-serif;
              color: black;
              align-self: flex-end;
            }

            .ticket-type {
              align-self: flex-end;
              background-color: var(--ticket-badge-bg);
              color: white;
              padding: 8px;
              font-size: 12px;
              border-radius: 3px;
            }
          }
        }
      }

      .no-data {
        margin: 0px;
      }
    }

  }

  .input-wrap label {
    color: var(--text-color-gray);
  }

  .content .withdraw-form,
  .content .verify-form,
  .content .solde-form,
  .content #myTab,
  .content #myTabContent,
  .content #myTabContent #home-tab-pane,
  .content #myTabContent #home-tab-pane .recharge-form,
  .content #myTabContent #home-tab-pane .recharge-form .content-grid,
  .content .withdraw-form .content-grid,
  .content .solde-form .content-grid,
  .content .withdraw-form .content-grid .input-wrap .form-label,
  .content .recharge-form .content-grid .input-wrap .form-label,
  .content .recharge-form .content-grid .input-wrap .form-label .deposit-input,
  .content .solde-form .content-grid .input-wrap .form-label,
  .content .withdraw-form .content-grid .input-wrap .form-label .withdraw-input,
  .content .solde-form .content-grid .input-wrap .form-label .solde-input,
  .content .withdraw-form .content-grid .btn-secondary,
  .content .solde-form .content-grid .btn-secondary,
  .content .recharge-form .content-grid .btn-secondary {
    width: 100% !important;
  }

  .content #myTab:not(.ref-selection) {
    background-color: var(--input-bg-gray);
    border-radius: 5px;
    padding: 0px;
    margin-bottom: 0px;

    .radio-option {
      height: 100%;
      position: relative;
      justify-items: center;

      input[type="radio"] {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        border: none;
        background-color: transparent;
        transition: all 300ms linear;
        position: absolute;

        & ~ label {
          color: var(--text-color-gray);
        }

        &:checked {
          background-color: #2855a7;

          & ~ label {
            color: white;
          }

          &::after {
            display: none;
          }
        }
      }

      label {
        position: absolute;
      }
    }
  }

  .recharge-form .content-grid .amounts button {
    font-size: 0.7rem;
  }
}

.form-text {
  font-size: 12px;
  color: #ff5f25;
  bottom: 0;
  padding: 20px 0;
}

@keyframes appear {
  0%,
  20% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
